import React, { useCallback } from 'react'
import cx from 'classnames'
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label
} from 'reactstrap'

import Select from 'components/Select'
import DayPickerInput from 'components/DayPickerInput'
import UploadFileInput from '../UploadFileInput'
import Switch from '../Switch'
import DropZone from 'components/modals/DocumentsFormModal/DropZone'

// export const renderFormInput = ({
//   input,
//   name,
//   placeholder,
//   disabled
// }) => {
//   return (
//     <Input className='form-control'
//       {...input}
//       id={name}
//       name={name}
//       placeholder={placeholder}
//       disabled={disabled} />
//   )
// }

// TODO: After refactoring I have two identical fields. Double check and remove if not needed in separate commit
export const renderTextField = ({
  input,
  placeholder,
  label,
  icon,
  type,
  disabled,
  meta: { touched, error }
}) => {
  const invalid = error && touched
  const { name } = input

  return (
    <InputGroup className='input-group mb-3'>
      {label && <label htmlFor={name}>{label}</label>}
      {icon && (
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            <i className={`icon-${icon}`} />
          </span>
        </div>
      )}
      <Input className='form-control'
        {...input}
        id={name}
        type={type}
        invalid={invalid}
        placeholder={placeholder}
        disabled={disabled} />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  )
}

export const renderFormGroupTextField = ({
  input,
  placeholder,
  label,
  icon,
  type,
  disabled,
  meta: { touched, error }
}) => {
  const invalid = error && touched
  const { name } = input

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup>
        {icon && (
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className={`icon-${icon}`} />
            </span>
          </div>
        )}
        <Input className='form-control'
          {...input}
          id={name}
          type={type}
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  )
}

export const renderSelectMappingField = ({
  label,
  mapping,
  isLoading,
  placeholder,
  input,
  disabled,
  isMulti,
  meta: { touched, error }
}) => {
  let options = Array.isArray(mapping) ? mapping : Object.keys(mapping).reduce((memo, key) => {
    memo.push({ value: key, label: mapping[key] })
    return memo
  }, [])

  const invalid = error && touched
  const { name, value, onChange, onBlur } = input

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <div className='InputContainer'>
        <Select
          {...input}
          id={name}
          isMulti={isMulti}
          options={options}
          invalid={invalid}
          isLoading={isLoading}
          placeholder={placeholder}
          disabled={disabled}
          value={isMulti ? options.filter((option) => value.includes(option.value)) : options.find((option) => option.value === value) || ''}
          onChange={(value) => {
            const inputValue = isMulti ? (value ? value.map(({ value }) => value) : '') : value.value
            onChange(inputValue)
          }}
          onBlur={() => onBlur(value)}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </div>
    </FormGroup>
  )
}

export const renderFileUploadInput = (props) => {
  const { meta: { touched, error }, label, input } = props
  const invalid = error && touched
  const { name } = input

  return (
    <FormGroup>
      <Label>{label}</Label>
      <UploadFileInput {...props}
        id={name}
        invalid={invalid} />
    </FormGroup>
  )
}

export const renderDateField = ({
  input,
  label,
  disabled,
  meta
}) => {
  const { touched, error } = meta
  const invalid = error && touched
  const { name, value } = input

  const inputClassNames = cx('form-control', { 'is-invalid': invalid })

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <div className='InputContainer'>
        <DayPickerInput id={name}
          value={value}
          invalid={invalid}
          inputProps={{ ...input, disabled: disabled, className: inputClassNames, autoComplete: 'off' }} />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </div>
    </FormGroup>
  )
}

export const renderSwitch = ({
  input,
  label,
  name
}) => {
  return (
    <FormGroup className='d-flex align-items-center'>
      {label && <Label for={name} className='mr-4'>{label}</Label>}
      <Switch {...input} />
    </FormGroup>
  )
}

export const DropZoneInput = ({
  id,
  input,
  label,
  placeholder
}) => {
  const { name, value, onChange } = input

  const onReset = useCallback(() => {
    onChange([])
  }, [name])

  return (
    <FormGroup className='d-flex align-items-center'>
      {label && <Label for={name} className='mr-4'>{label}</Label>}

      <DropZone
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        multiple
        onReset={onReset}
      />
    </FormGroup>
  )
}
