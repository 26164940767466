import React from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal
} from 'reactstrap'

import styles from './FormModalBase.module.scss'

const ConfirmationModal = (props) => {
  const {
    title,
    text,
    isOpen,
    toggle,
    onSubmit,
    onCancel
  } = props

  const onClose = () => {
    onCancel && onCancel()
    toggle()
  }

  return (
    <Modal className={styles.FormModalBase} isOpen={isOpen} toggle={toggle}>
      <Card>
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close' onClick={onClose}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          <pre>
            {text}
          </pre>
        </CardBody>
        <CardFooter>
          <ButtonGroup>
            <Button className='mr-3' color='success' size='xs' onClick={onSubmit}>
              <i className='fa fa-check' style={{ color: '#ffffff' }} />
            </Button>
            <Button color='danger' size='xs' onClick={onClose}>
              <i className='fa fa-times' style={{ color: '#ffffff' }} />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </Modal>
  )
}

export default ConfirmationModal
