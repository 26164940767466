import { connect } from 'react-redux'

import {
  ADMIN,
  EDITOR,
  AGENT
} from 'constants/users'

const mapStateToProps = state => {
  const {
    isAuthenticated,
    isAuthenticating,
    isRestoring,
    isProcessing,
    error,
    user
  } = state.auth

  return {
    isAuthenticated,
    isAuthenticating,
    isRestoring,
    isProcessing,
    error,
    user
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isAuthenticated, user } = stateProps

  return {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    isStaff: isAuthenticated && user && user.role === ADMIN,
    isEditor: isAuthenticated && user && user.role === EDITOR,
    isAgent: isAuthenticated && user && user.role === AGENT
  }
}

export default connect(mapStateToProps, null, mergeProps)
