import React from 'react'

export default (props) => {
  const { rowInfo } = props

  const now = Date.now()
  const creationDate = new Date(rowInfo.node.createdAt)
  const diff = Math.ceil((now - creationDate) / (1000 * 60 * 60 * 24))

  const isNew = diff <= 2

  const inlineStyles = isNew
    ? { color: '#17a2b8' }
    : {}

  return <i className='far fa-file mr-2' style={inlineStyles} />
}
