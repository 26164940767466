import React, { Fragment, useState } from 'react'
import { Container } from 'reactstrap'
import cx from 'classnames'

import withAuth from 'containers/auth.container'
import Sidebar from '../Navbar/Sidebar'
import Header from '../Header'

const DefaultLayout = ({ isStaff, isEditor, isAgent, user, isAuthenticated, children, container }) => {
  const [toggleClass, setToggleClass] = useState('sidebar-lg-show')

  const onToggleSidebar = (event) => {
    const { toggle } = event.currentTarget.dataset
    setToggleClass(toggleClass && toggle === toggleClass ? null : toggle)
  }

  return (
    <div>
      <div className={cx('app header-fixed sidebar-fixed aside-menu-fixed', toggleClass)}>
        <Header isAuthenticated={isAuthenticated} user={user} onToggleSidebar={onToggleSidebar} />

        <div className='app-body'>
          {isAuthenticated && <Sidebar isStaff={isStaff}
            isEditor={isEditor}
            isAgent={isAgent} /> }
          <main className='main'>
            {container ? (
              <Container className='d-flex justify-content-center align-items-center h-100'>
                {children}
              </Container>
            ) : (
              <Fragment>
                {children}
              </Fragment>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

DefaultLayout.defaultProps = {
  container: true
}

export default withAuth(DefaultLayout)
