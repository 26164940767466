import React from 'react'
import { Field, formPropTypes, reduxForm } from 'redux-form'

import { renderTextField } from './formFields'
import { email, required } from './validators'

const SignInForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        type='email'
        name='email'
        icon='user'
        placeholder='Inserisci la tua email'
        component={renderTextField}
        validate={[required, email]}
      />

      <Field
        type='password'
        name='password'
        icon='lock'
        placeholder='Inserisci la password'
        component={renderTextField}
        validate={[required]}
      />

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Accedi
      </button>
    </form>
  )
}

SignInForm.propTypes = formPropTypes

export default reduxForm({
  form: 'signInForm'
})(SignInForm)
