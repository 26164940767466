import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Col, Row } from 'reactstrap'
import { change, Field, FieldArray, formPropTypes, formValueSelector, reduxForm } from 'redux-form'

import { renderFormGroupTextField } from 'components/forms/formFields'
import { required } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'
import FormModal from './FormModal'

import styles from './FormModalBase.module.scss'
import { renderPermissions } from './DocumentFormModal/renderPermissions'
import { connect } from 'react-redux'

const CategoryFormModal = (props) => {
  const { title, error, users, user, disabled, groups, modalState, usersGroupsIsLoading } = props
  const author = props.initialValues ? props.initialValues.author : null

  const [usersMapping, setUsersMapping] = useState([])
  const [groupsMapping, setGroupsMapping] = useState([])

  useEffect(() => {
    setUsersMapping(users
      .filter(({ _id }) => _id !== user._id)
      .reduce((memo, { _id, name, surname, email }) => {
        memo[_id] = `${name} ${surname} (${email})`
        return memo
      }, {}))
  }, [users])

  useEffect(() => {
    setGroupsMapping(
      groups
        .filter(group => {
          return author === user._id || !group.users.find(({ _id }) => _id === user._id)
        })
        .reduce((memo, { _id, name }) => {
          memo[_id] = name
          return memo
        }, {}))
  }, [groups])

  const onAddPermission = (fields) => {
    fields.push({
      type: '',
      grantee: '',
      access: 'view',
      isNew: true
    })
  }

  const onRemovePermission = (fields, index) => {
    fields.remove(index)
  }

  const onPermissionTypeChange = (field) => {
    props.change('documentFormModal', `${field}.grantee`, '')
  }

  return (
    <FormModal className={cx(styles.FormModalBase)} {...props} formTitle={title}>
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            name='parent'
            type='hidden'
            style={{ height: 0 }}
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldArray name='permissions'
            disabled={disabled}
            permissions={modalState.permissions || []}
            users={usersMapping}
            groups={groupsMapping}
            isLoading={usersGroupsIsLoading}
            onPermissionTypeChange={onPermissionTypeChange}
            onAddPermission={onAddPermission}
            onRemovePermission={onRemovePermission}
            component={renderPermissions} />
        </Col>
      </Row>
    </FormModal>
  )
}

CategoryFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

CategoryFormModal.defaultProps = {
  title: 'Categoria',
  categories: [],
  users: [],
  groups: []
}

const selector = formValueSelector('categoryFormModal')

const mapStateToProps = (state) => {
  const permissions = selector(state, 'permissions')

  return {
    user: state.auth.user,
    modalState: {
      permissions: permissions
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change: (form, field, value) => { dispatch(change(form, field, value)) }
  }
}

export default reduxForm({
  form: 'categoryFormModal',
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(CategoryFormModal))
