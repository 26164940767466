import React, { Component } from 'react'
import cx from 'classnames'
import ReactDayPickerInput from 'react-day-picker/DayPickerInput'
import {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

class DayPickerInput extends Component {
  static FORMAT = 'DD/MM/YYYY'

  constructor (props) {
    super(props)

    this.dayPickerInputRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  checkDayPickerInViewport = () => {
    if (this.dayPickerInputRef.current) {
      const dayPicker = this.dayPickerInputRef.current.getDayPicker()
      const dayPickerInput = this.dayPickerInputRef.current.getInput()
      if (dayPicker) {
        const dayPickerElem = dayPicker.dayPicker
        if (dayPickerElem) {
          const dayPickerBounds = dayPickerElem.getBoundingClientRect()

          const overlay = dayPickerElem.parentNode
          if (dayPickerBounds.right > window.innerWidth) {
            overlay.style.right = 0
            overlay.style.left = 'auto'
          } else {
            if (dayPickerInput) {
              const dayPickerInputBounds = dayPickerInput.getBoundingClientRect()
              if (dayPickerInputBounds.x + dayPickerBounds.width < window.innerWidth) {
                overlay.style.right = 'auto'
                overlay.style.left = 0
              }
            }
          }
        }
      }
    }
  }

  onKeyDown = (event) => {
    const { keyCode } = event

    if (keyCode === 13) {
      const { inputProps, value } = this.props
      this.dayPickerInputRef.current.input.blur()
      this.dayPickerInputRef.current.hideDayPicker()
      inputProps.onChange(value)
    }
  }

  onBlur = () => {
    const { inputProps, value } = this.props
    inputProps.onChange(value)
  }

  onResize = () => {
    this.checkDayPickerInViewport()
  }

  render () {
    const {
      id,
      invalid,
      value,
      inputProps
    } = this.props

    const classNames = {
      container: cx('DayPickerInput', { 'DayPickerInvalid': invalid }),
      overlayWrapper: 'DayPickerInput-OverlayWrapper',
      overlay: 'DayPickerInput-Overlay'
    }

    return (
      <ReactDayPickerInput
        ref={this.dayPickerInputRef}
        id={id}
        classNames={classNames}
        onDayPickerShow={this.checkDayPickerInViewport}
        value={value ? formatDate(value, DayPickerInput.FORMAT) : value}
        formatDate={formatDate}
        parseDate={parseDate}
        format={DayPickerInput.FORMAT}
        inputProps={{ ...inputProps, onKeyDown: this.onKeyDown, onBlur: this.onBlur }}
        placeholder={value === '' ? DayPickerInput.FORMAT : formatDate(new Date(value), DayPickerInput.FORMAT)}
        onDayChange={inputProps.onChange} />
    )
  }
}

export default DayPickerInput
