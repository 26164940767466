import React, { useState } from 'react'

import { ActionsFormatterGeneric, UsersRoleFormatter } from 'components/tables/formatters'
import UsersService from 'services/UsersService'
import User from 'models/user'
import AddUserFormModal from 'components/modals/AddUserFormModal/AddUserFormModal'
import withAuth from 'containers/auth.container'

import DataTable from './DataTable'

const getColumns = (props) => (
  [
    {
      key: 'name',
      name: 'Nome',
      sortable: true,
      filterable: true
    },
    {
      key: 'surname',
      name: 'Cognome',
      sortable: true,
      filterable: true
    },
    {
      key: 'email',
      name: 'Email',
      sortable: true,
      filterable: true
    },
    {
      key: 'role',
      name: 'Ruolo',
      formatter: UsersRoleFormatter,
      sortable: true,
      cellClass: 'text-center',
      filterable: true
    },
    {
      key: 'actions',
      name: 'Azioni',
      formatter: ActionsFormatterGeneric(props),
      cellClass: 'justify-content-center'
    }
  ]
)

const UsersDataTable = (props) => {
  const [users, setUsers] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const fetchUsers = ({ page: { number, size }, formFilterData }) => {
    UsersService
      .getUsers(number, size, formFilterData)
      .then((response) => {
        const { user } = props
        const { data: { results, totalRecords } } = response
        const users = results.map(response => User.fromResponse(response)).filter(({ _id }) => _id !== user._id)

        setUsers(users)
        setTotalRows(totalRecords)
      })
      .catch(() => { })
  }

  const onChange = (params) => {
    fetchUsers(params)
  }

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues }

      let request = UsersService[action]

      if (request) {
        if (action === 'create') {
          request = request(body)
        }

        if (action === 'update') {
          const { _id } = body
          request = request(_id, body)
        }

        if (action === 'delete') {
          const { _id } = body
          request = request(_id, body)
        }

        return request
      }
    }
  }

  const getFormModalModeProps = (mode) => {
    if (mode === 'create') {
      return {
        title: 'Crea utente',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'update') {
      return {
        title: 'Aggiorna utente',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'delete') {
      return {
        title: 'Elimina utente',
        onSubmit: handleSubmit(mode)
      }
    }

    return {
      submitButtonLabel: 'Submit',
      title: 'Utente'
    }
  }

  return (
    <DataTable columns={getColumns}
      data={users}
      totalRowsCount={totalRows}
      onChange={onChange}
      formModalProps={{
        formModalComponent: AddUserFormModal,
        getFormModalModeProps: getFormModalModeProps
      }}
    />
  )
}

export default withAuth(UsersDataTable)
