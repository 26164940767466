import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import createRootReducer from '../reducers'

// TODO remove logger for prod env
export default function configureStore (initialState) {
  const reducer = createRootReducer()

  const middleware = composeWithDevTools(
    applyMiddleware(
      thunk,
      createLogger()
    )
  )

  const store = createStore(reducer, initialState, middleware)

  store.asyncReducers = {}

  store.attachReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer
    store.replaceReducer(createRootReducer(store.asyncReducers))

    return store
  }

  store.detachReducer = (key) => {
    if (key in store.asyncReducers) {
      delete store.asyncReducers[key]

      store.replaceReducer(createRootReducer(store.asyncReducers))
    }

    return store
  }

  return store
}
