import React, { Component } from 'react'
import { pick } from 'lodash'
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import saveAs from 'file-saver'
import {
  addNodeUnderParent,
  changeNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  removeNodeAtPath,
  toggleExpandedForAll
} from 'react-sortable-tree'

import DocumentsTreeView from 'components/DocumentsTreeView'
import DocumentsTreeToolbar from 'components/DocumentsTreeView/DocumentsTreeToolbar'

import ReduxModalWrapper from 'components/modals/reduxModal/ReduxModalWrapper'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import CategoryFormModal from 'components/modals/CategoryFormModal'
import DocumentFormModal from 'components/modals/DocumentFormModal'
import DocumentsFormModal from 'components/modals/DocumentsFormModal'
import ModalsService from 'services/ModalsService'
import CategoryService from 'services/CategoryService'
import DocumentService from 'services/DocumentService'
import UsersService from 'services/UsersService'
import GroupsService from 'services/GroupsService'
import { AGENT, EDITOR } from 'constants/users'
import FileUploadService from 'services/FileUploadService'
import { extractFileName } from 'helpers/responseHelper'
import handleFormSubmitError from 'helpers/forms/handleFormSubmitError'

const modals = [
  <ReduxModalWrapper key='CategoryFormModal' modal='CategoryFormModal' component={CategoryFormModal} />,
  <ReduxModalWrapper key='DocumentFormModal' modal='DocumentFormModal' component={DocumentFormModal} />,
  <ReduxModalWrapper key='DocumentsFormModal' modal='DocumentsFormModal' component={DocumentsFormModal} />,
  <ReduxModalWrapper key='ConfirmationModal' modal='ConfirmationModal' component={ConfirmationModal} />
]

class Documents extends Component {
  constructor (props) {
    super(props)

    this.state = {
      searchString: '',
      searchFocusIndex: 0,
      searchFoundCount: null,
      documentsTreeData: [],
      users: [],
      groups: [],
      usersGroupsIsLoading: true
    }
  }

  componentDidMount () {
    const { isAgent } = this.props

    Promise.all([
      DocumentService.index(),
      CategoryService.index()
    ]).then(([documentsResponse, categoriesResponse]) => {
      const documents = documentsResponse.data.documents.map((document) => ({
        ...document,
        parent: document.category,
        dragDisabled: document.access === 'view',
        isDirectory: false
      }))

      let categories = categoriesResponse.data.categories.map((category) => ({
        ...category,
        title: category.name,
        isDirectory: true,
        dragDisabled: category.name === 'root' || category.access === 'view',
        expanded: true
      }))

      categories = categories.sort((n1, n2) => {
        if (n1.folderIndex < n2.folderIndex) {
          return -1
        } else if (n1.folderIndex > n2.folderIndex) {
          return 1
        } else {
          return 0
        }
      })

      // const nodes = [...documents, ...categories].sort((n1, n2) => {
      //   if (n1.isDirectory !== n2.isDirectory) {
      //     return +n2.isDirectory - +n1.isDirectory
      //   }
      //   // if (n1.createdAt > n2.createdAt) {
      //   //   return -1
      //   // }

      //   // if (n1.createdAt < n2.createdAt) {
      //   //   return 1
      //   // }

      //   return 0
      // })

      const nodes = [...documents, ...categories]

      const treeData = getTreeFromFlatData({
        flatData: nodes,
        getKey: node => node._id,
        getParentKey: node => node.parent,
        rootKey: null
      })

      this.setState({ documentsTreeData: treeData })
    })

    if (!isAgent) {
      Promise.all([
        UsersService.index(),
        GroupsService.index()
      ]).then(values => {
        const users = values[0].data.users.filter(user => user.role === EDITOR)
        const groups = values[1].data.groups

        this.setState({ users, groups, usersGroupsIsLoading: false })
      })
    }
  }

  onChange = (documentsTreeData) => {
    this.setState({ documentsTreeData })
  }

  expandAll = (expanded) => {
    this.setState({
      documentsTreeData: toggleExpandedForAll({
        treeData: this.state.documentsTreeData,
        expanded
      })
    })
  }

  expand = () => {
    this.expandAll(true)
  }

  collapse = () => {
    this.expandAll(false)
  }

  selectPrevMatch = () => {
    const {
      searchFocusIndex,
      searchFoundCount
    } = this.state

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    })
  }

  selectNextMatch = () => {
    const {
      searchFocusIndex,
      searchFoundCount
    } = this.state

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    })
  }

  removeNode = ({ node, path }) => {
    const { documentsTreeData } = this.state
    const getNodeKey = ({ treeIndex }) => treeIndex

    const modalTitle = node.isDirectory ? 'Elimina categoria' : 'Elimina documento'
    const modalText = node.isDirectory
      ? 'Sei sicuro di eliminare questa categoria? Tutti i documenti ad essa associati saranno eliminati.'
      : 'Sei sicuro di eliminare questo documento?'

    const submitRemove = () => {
      const service = node.isDirectory ? CategoryService : DocumentService
      service
        .delete(node._id)
        .then(() => {
          this.setState(
            {
              documentsTreeData: removeNodeAtPath({
                treeData: documentsTreeData,
                path,
                getNodeKey
              })
            }
          )
        })
    }

    if (!node.isDirectory || (node.children && node.children.length > 0)) {
      ModalsService.open(
        'ConfirmationModal',
        {
          title: modalTitle,
          text: modalText,
          onSubmit: () => {
            submitRemove()
            ModalsService.close('ConfirmationModal')
          },
          onCancel: () => {
            ModalsService.close('ConfirmationModal')
          }
        }
      )
    } else {
      submitRemove()
    }
  }

  editCategory = (rowInfo) => {
    const { path, node } = rowInfo
    const getNodeKey = ({ treeIndex }) => treeIndex

    const { documentsTreeData, users, groups, usersGroupsIsLoading } = this.state

    ModalsService.open('CategoryFormModal', {
      title: 'Nuova Categoria',
      users: users,
      groups: groups,
      access: node.access,
      usersGroupsIsLoading: usersGroupsIsLoading,
      initialValues: {
        ...node
      },
      onSubmit: (body) => {
        return CategoryService
          .update(node._id, pick(body, ['name', 'permissions']))
          .then((response) => {
            ModalsService.close('CategoryFormModal')

            const { data: { category } } = response

            this.setState({
              documentsTreeData: changeNodeAtPath({
                treeData: documentsTreeData,
                path,
                getNodeKey,
                newNode: { ...node, ...category, title: category.name }
              })
            })
          })
          .catch((error) => {
            handleFormSubmitError(error)
          })
      },
      onCancel: () => {
        ModalsService.close('CategoryFormModal')
      }
    })
  }

  editNode = (rowInfo) => {
    const { path, node } = rowInfo
    const getNodeKey = ({ treeIndex }) => treeIndex
    const { documentsTreeData, users, groups, usersGroupsIsLoading } = this.state

    const categories = getFlatDataFromTree({
      treeData: documentsTreeData,
      getNodeKey: ({ node }) => node._id,
      ignoreCollapsed: false
    }).map(({ node }) => node)

    ModalsService.open('DocumentFormModal', {
      title: 'Nuovo Documento',
      categories: categories,
      users: users,
      groups: groups,
      access: node.access,
      usersGroupsIsLoading: usersGroupsIsLoading,
      initialValues: {
        ...node
      },
      onSubmit: (body) => {
        return DocumentService
          .update(node._id, body)
          .then((response) => {
            ModalsService.close('DocumentFormModal')
            const { data: { document } } = response

            this.setState({
              documentsTreeData: changeNodeAtPath({
                treeData: documentsTreeData,
                path,
                getNodeKey,
                newNode: { ...document, parent: document.category, isDirectory: false }
              })
            })
          })
          .catch((error) => {
            handleFormSubmitError(error)
          })
      },
      onCancel: () => {
        ModalsService.close('DocumentFormModal')
      }
    })
  }

  addNewCategory = (rowInfo) => {
    const { path, node } = rowInfo
    const getNodeKey = ({ treeIndex }) => treeIndex

    const { documentsTreeData, users, groups, usersGroupsIsLoading } = this.state

    ModalsService.open('CategoryFormModal', {
      title: 'Nuova Categoria',
      users: users,
      groups: groups,
      access: node.access,
      usersGroupsIsLoading: usersGroupsIsLoading,
      initialValues: {
        parent: node._id
      },
      onSubmit: (body) => {
        CategoryService
          .create(body)
          .then((response) => {
            ModalsService.close('CategoryFormModal')

            const { data: { category } } = response

            this.setState({
              documentsTreeData: addNodeUnderParent({
                treeData: documentsTreeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey,
                newNode: {
                  ...category,
                  title: category.name,
                  isDirectory: true
                },
                addAsFirstChild: false
              }).treeData
            })
          })
      },
      onCancel: () => {
        ModalsService.close('CategoryFormModal')
      }
    })
  }

  addNewDocument = (rowInfo) => {
    const { path, node } = rowInfo
    const getNodeKey = ({ treeIndex }) => treeIndex

    const { documentsTreeData, users, groups, usersGroupsIsLoading } = this.state

    const categories = getFlatDataFromTree({
      treeData: documentsTreeData,
      getNodeKey: ({ node }) => node._id,
      ignoreCollapsed: false
    }).map(({ node }) => node)

    ModalsService.open('DocumentFormModal', {
      title: 'Nuovo Documento',
      categories: categories,
      users: users,
      groups: groups,
      usersGroupsIsLoading: usersGroupsIsLoading,
      initialValues: {
        category: node._id
      },
      onSubmit: (body) => {
        DocumentService
          .create(body)
          .then((response) => {
            ModalsService.close('DocumentFormModal')
            const { data: { document } } = response

            this.setState({
              documentsTreeData: addNodeUnderParent({
                treeData: documentsTreeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey,
                newNode: {
                  ...document,
                  parent: document.category,
                  isDirectory: false
                },
                addAsFirstChild: true
              }).treeData
            })
          })
      },
      onCancel: () => {
        ModalsService.close('DocumentFormModal')
      }
    })
  }

  addNewDocuments = (rowInfo) => {
    const { path, node } = rowInfo
    const getNodeKey = ({ treeIndex }) => treeIndex

    const { documentsTreeData, users, groups, usersGroupsIsLoading } = this.state

    const categories = getFlatDataFromTree({
      treeData: documentsTreeData,
      getNodeKey: ({ node }) => node._id,
      ignoreCollapsed: false
    }).map(({ node }) => node)

    ModalsService.open('DocumentsFormModal', {
      title: 'Nuovo Documento',
      categories: categories,
      users: users,
      groups: groups,
      usersGroupsIsLoading: usersGroupsIsLoading,
      initialValues: {
        category: node._id,
        files: [],
        version: 1
      },
      onSubmit: (values) => {
        const uploads = values.files.map(file => {
          return FileUploadService
            .upload(file)
            .then((response) => {
              const { data: { uploadedFileData } } = response
              return uploadedFileData
            })
        })

        Promise.all(uploads).then(uploadedFilesData => {
          const result = uploadedFilesData.map(uploadedFileData => {
            // eslint-disable-next-line
            const { url, original_file_name } = uploadedFileData
            const { category, version } = values

            const body = {
              category: category,
              version: version,
              file: url,
              title: original_file_name
            }

            return DocumentService.create(body)
          })

          Promise.all(result).then(createdDocuments => {
            const treeData = createdDocuments.reduce((treeData, response) => {
              const { data: { document } } = response

              return addNodeUnderParent({
                treeData: treeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey,
                newNode: {
                  ...document,
                  parent: document.category,
                  isDirectory: false
                },
                addAsFirstChild: true
              }).treeData
            }, documentsTreeData)

            this.setState(() => ({
              documentsTreeData: treeData
            }), () => {
              ModalsService.close('DocumentsFormModal')
            })
          })
        })
      },
      onCancel: () => {
        ModalsService.close('DocumentsFormModal')
      }
    })
  }

  download = ({ node }) => {
    FileUploadService.download(node._id).then(response => {
      const filename = extractFileName(response.headers['content-disposition'])
      saveAs(response.data, filename)
    })
  }

  canDrop = ({ nextParent, ...rest }) => {
    if (nextParent && nextParent.isDirectory) {
      const prevPath = rest.prevPath.slice(0, rest.prevPath.length - 1).join(',')
      const nextPath = rest.nextPath.slice(0, rest.nextPath.length - 1).join(',')
      return nextPath === prevPath
    }
    return false
  }

  onMoveNode = ({ node, nextParentNode, treeData }) => {
    console.log('nextParentNode')
    console.log(nextParentNode)
    if (node.isDirectory) {
      let folderPositions = {}
      for (let folderNode of nextParentNode.children) {
        const folderIndex = nextParentNode.children.map(e => e._id).indexOf(folderNode._id)
        folderPositions[folderNode._id] = folderIndex
      }
      CategoryService.updateFolderPosition(folderPositions).then(() => { })
    } else {
      let folderPositions = {}
      for (let folderNode of nextParentNode.children) {
        const folderIndex = nextParentNode.children.map(e => e._id).indexOf(folderNode._id)
        folderPositions[folderNode._id] = folderIndex
      }
      DocumentService.updateFolderPosition(folderPositions).then(() => { })
    }
  }

  nodeSearch = ({ node, searchQuery }) =>
    searchQuery &&
    node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1

  onSearchFinishCallback = (matches) => {
    const { searchFocusIndex } = this.state

    this.setState({
      searchFoundCount: matches.length,
      searchFocusIndex:
        matches.length > 0 ? searchFocusIndex % matches.length : 0
    })
  }

  onSearchStringChange = (value) => {
    this.setState({ searchString: value })
  }

  fetchCategoryDocuments = (value) => {
    const { documentsTreeData } = this.state
    CategoryService.documents(value).then(response => {
      const documents = response.data.documents.map((document) => ({
        ...document,
        parent: document.category,
        dragDisabled: document.access === 'view',
        isDirectory: false
      }))

      const flatData = getFlatDataFromTree({
        treeData: documentsTreeData,
        getNodeKey: ({ node }) => node._id,
        ignoreCollapsed: false
      }).map(({ node }) => node).filter(v => (!v.isDirectory && v.parent !== value) || v.isDirectory)

      const nodes = [...flatData, ...documents].sort((n1, n2) => {
        if (n1.isDirectory !== n2.isDirectory) {
          return +n2.folderIndex - +n1.folderIndex
        }

        return 0
      })

      console.log(nodes)

      const treeData = getTreeFromFlatData({
        flatData: nodes,
        getKey: node => node._id,
        getParentKey: node => node.parent,
        rootKey: null
      })

      this.setState({ documentsTreeData: treeData })
    })
  }

  generateNodeProps = (nodeProps, rowInfo) => {
    const { isAgent } = this.props
    const { node } = rowInfo
    const canManage = node.access === 'edit'

    const buttons = []

    if (isAgent) {
      if (!node.isDirectory) {
        buttons.push(
          <Button className='ml-2' color='success' size='xs' onClick={() => this.download(rowInfo)}>
            <i className='fas fa-download' style={{ color: '#ffffff' }} title='Download' />
          </Button>
        )
      }
    } else {
      if (node.isDirectory) {
        if (canManage) {
          buttons.push(
            <Button className='mr-2' color='success' size='xs' onClick={() => this.addNewCategory(rowInfo)} title='Nuova categoria'>
              <i className='fas fa-folder-plus' style={{ color: '#ffffff' }} title='Nuova categoria' />
            </Button>
          )
          if (node.name !== 'root') {
            buttons.push(
              <Button className='mr-2' color='warning' size='xs' onClick={() => this.editCategory(rowInfo)} title='Modifica'>
                <i className='fas fa-pen' style={{ color: '#ffffff' }} title='Modifica' />
              </Button>
            )
          }
        }
        buttons.push(
          <Button className='mr-2' color='success' size='xs' onClick={() => this.addNewDocument(rowInfo)} title='Nuovo documento'>
            <i className='fas fa-plus' style={{ color: '#ffffff' }} title='Nuovo documento' />
          </Button>
        )
        buttons.push(
          <Button className='mr-2' color='success' size='xs' onClick={() => this.addNewDocuments(rowInfo)} title='Nuovi documenti'>
            <i className='fas fa-plus mr-1' style={{ color: '#ffffff' }} />
            <i className='fas fa-plus' style={{ color: '#ffffff' }} title='Nuovi documenti' />
          </Button>
        )
        buttons.push(
          <Button color='info' size='xs' onClick={() => this.fetchCategoryDocuments(node._id)} title='Espandi categoria'>
            <i className='fas fa-file-download' style={{ color: '#ffffff' }} title='Espandi categoria' />
          </Button>
        )
      } else {
        if (canManage) {
          buttons.push(
            <Button className='ml-2' color='warning' size='xs' onClick={() => this.editNode(rowInfo)} title='Modifica'>
              <i className='fas fa-pen' style={{ color: '#ffffff' }} title='Modifica' />
            </Button>
          )
        }

        buttons.push(
          <Button className='ml-2' color='success' size='xs' onClick={() => this.download(rowInfo)} title='Download'>
            <i className='fas fa-download' style={{ color: '#ffffff' }} title='Download' />
          </Button>
        )
      }

      if (!node.isDirectory || node.name !== 'root') {
        if (canManage) {
          buttons.push(
            <Button className='ml-2' color='danger' size='xs' onClick={() => this.removeNode(rowInfo)} title='Elimina'>
              <i className='fas fa-trash' style={{ color: '#ffffff' }} title='Elimina' />
            </Button>
          )
        }
      }
    }

    return {
      ...nodeProps,
      buttons: buttons
    }
  }

  render () {
    const { documentsTreeData, searchString, searchFocusIndex, searchFoundCount } = this.state
    const { isAgent } = this.props

    return (
      <div className='w-100 h-100' style={{ paddingTop: '80px', paddingBottom: '80px' }}>
        <Card className='w-100 h-100'>
          <CardHeader>
            <DocumentsTreeToolbar searchString={searchString}
              searchFoundCount={searchFoundCount}
              onSearchStringChange={this.onSearchStringChange}
              onExpandAll={this.expand}
              onCollapseAll={this.collapse}
              onSelectNextMatch={this.selectNextMatch}
              onSelectPrevMatch={this.selectPrevMatch} />
          </CardHeader>
          <CardBody className='w-100 h-100' style={{ maxHeight: 700 }}>
            <DocumentsTreeView treeData={documentsTreeData}
              canDrop={this.canDrop}
              onMoveNode={this.onMoveNode}
              onChange={this.onChange}
              searchQuery={searchString}
              searchMethod={this.nodeSearch}
              searchFocusOffset={searchFocusIndex}
              onSearchFinishCallback={this.onSearchFinishCallback}
              generateNodeProps={this.generateNodeProps} />
          </CardBody>
        </Card>
        {!isAgent && modals}
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isAgent: auth.user.role === AGENT
  }
}

export default connect(mapStateToProps)(Documents)
