import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { ADMIN, AGENT, EDITOR } from 'constants/users'
import SignInView from 'containers/views/SignInView'
import PublicRoute from 'components/routes/PublicRoute'
import OnlyPublicRoute from 'components/routes/OnlyPublic'
import DefaultLayout from 'components/layouts/DefaultLayout'
import PrivateRoute from 'components/routes/PrivateRoute'
import StaffRoute from 'components/routes/StaffRoute'
import ProfileRouter from 'components/profile/ProfileRouter'
import ResumeActivationView from 'containers/views/ResumeActivationView'
import ResetPasswordView from 'containers/views/ResetPasswordView'
import withAuth from 'containers/auth.container'
import ChangePasswordView from 'containers/views/ChangePasswordView'
import Users from 'containers/views/Users'
import Groups from 'containers/views/Groups'
import Documents from 'containers/views/Documents'

const roleToDefaultPAge = {
  [ADMIN]: '/users',
  [EDITOR]: '/documents',
  [AGENT]: '/documents'
}

const ApplicationRouter = ({ user }) => {
  return (
    <Switch>
      <OnlyPublicRoute path='/signin' component={SignInView} />

      <PublicRoute path='/resume-activation/:token' component={ResumeActivationView} />
      <PublicRoute path='/change-password/:token' component={ChangePasswordView} />
      <PublicRoute path='/recover' component={ResetPasswordView} />

      <PrivateRoute path='/profile' layout={DefaultLayout} component={ProfileRouter} allowedRoles={[ADMIN, EDITOR, AGENT]} container={false} />

      <StaffRoute path='/users' layout={DefaultLayout} component={Users} allowedRoles={[ADMIN]} />
      <StaffRoute path='/groups' layout={DefaultLayout} component={Groups} allowedRoles={[ADMIN]} />
      <PrivateRoute path='/documents' layout={DefaultLayout} component={Documents} allowedRoles={[ADMIN, EDITOR, AGENT]} />

      <PrivateRoute exact path='/' layout={DefaultLayout} component={() => {
        return <Redirect to={roleToDefaultPAge[user.role]} />
      }} allowedRoles={[ADMIN, EDITOR, AGENT]} />
    </Switch>
  )
}

export default withAuth(ApplicationRouter)
