import React, { Component } from 'react'
import styles from './DropZone.module.scss'
import ReactDropzone from './ReactDropzone/ReactDropzone'

import fileIcon from './icons/file.svg'
import closeIcon from './icons/close.svg'

class DropZone extends Component {
  constructor (props) {
    super(props)

    this.state = {
      files: [],
      isMobile: window.innerWidth <= 767
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { isMobile } = this.state

    if (window.innerWidth <= 767) {
      if (!isMobile) {
        this.setState({
          isMobile: true
        })
      }
    } else {
      if (isMobile) {
        this.setState({
          isMobile: false
        })
      }
    }
  }

  onDrop = (acceptedFiles) => {
    const { onChange } = this.props
    const files = [...this.state.files, ...acceptedFiles]

    this.setState(() => {
      return {
        files: files
      }
    }, () => {
      onChange && onChange(this.state.files)
    })
  }

  renderFilesPreview ({ selectedFiles }) {
    return selectedFiles.map((file, index) => {
      return (
        <div className={styles.fileRoot} key={index}>
          <img src={fileIcon} style={{ width: '24px', marginRight: '10px' }} />
          <div className={styles.placeholder}>{file.name}</div>
          <img className={styles.close} src={closeIcon} onClick={() => {
            const files = [...this.state.files]
            files.splice(index, 1)
            this.setState({ files })
          }}
          />
        </div>
      )
    })
  }

  renderDropZone ({ rootProps, inputProps, isDragActive }) {
    const { name } = this.props
    const { isMobile } = this.state

    return (
      <div className={styles.root} {...rootProps}>
        <input name={name} {...inputProps} />
        {
          isMobile ? (
            <div className={styles.placeholder}>Browse for a file</div>
          ) : (
            isDragActive
              ? <div className={styles.placeholder}>Drop here</div>
              : <div className={styles.placeholder}>Drag and drop a file to attach it, or browse for a file</div>
          )

        }
      </div>
    )
  }

  render () {
    const { value, multiple } = this.props
    const { files } = this.state

    return (
      <ReactDropzone
        onDrop={this.onDrop}
        multiple
      >
        {({ getRootProps, getInputProps, isDragActive, reset, ...rest }) => {
          if (multiple) {
            return (
              <div className='w-100'>
                <div className='mb-4'>
                  {value && value.length > 0 && this.renderFilesPreview({ selectedFiles: files, reset })}
                </div>
                <div>
                  {this.renderDropZone({ rootProps: getRootProps(), inputProps: getInputProps(), isDragActive })}
                </div>
              </div>
            )
          } else {
            return (
              !value || value.length === 0
                ? this.renderDropZone({ rootProps: getRootProps(), inputProps: getInputProps(), isDragActive })
                : this.renderFilesPreview({ selectedFiles: value, reset })
            )
          }
        }}
      </ReactDropzone>
    )
  }
}

export default DropZone
