import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Col, Row } from 'reactstrap'
import { Field, formPropTypes, reduxForm } from 'redux-form'

import {
  renderFormGroupTextField,
  renderSelectMappingField
} from 'components/forms/formFields'
import FormModal from '../FormModal'

import { required } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'

import UsersService from 'services/UsersService'
import User from 'models/user'
import { AGENT, EDITOR } from 'constants/users'

import groupsFormStyles from './GroupFormModal.module.scss'
import styles from '../FormModalBase.module.scss'

const GroupsFormModal = (props) => {
  const { title, error, disabled, isOpen } = props

  const [users, setUsers] = useState({ isLoading: false, data: [], isLoaded: false })

  useEffect(() => {
    if (isOpen && !users.isLoaded) {
      setUsers({ isLoading: true, isLoaded: false, data: [] })

      UsersService
        .index()
        .then((response) => {
          const { data: { users } } = response
          const usersMapping = users
            .map(user => new User(user))
            .reduce((memo, { _id, name, surname, role, active }) => {
              if (active && (role === EDITOR || role === AGENT)) {
                memo[_id] = `${name} ${surname}`
              }

              return memo
            }, {})

          setUsers({ isLoading: false, isLoaded: true, data: usersMapping })
        })
        .catch(() => {
          setUsers({ isLoading: false, isLoaded: false, data: [] })
        })
    }
  }, [isOpen, users.isLoaded])

  return (
    <FormModal className={cx(styles.FormModalBase, groupsFormStyles.GroupsFormStyles)} {...props} formTitle={title}>
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Nome'
            name='name'
            disabled={disabled}
            validate={disabled ? [required] : []}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Utenti'
            name='users'
            isMulti
            mapping={users.data}
            isLoading={users.isLoading}
            disabled={disabled}
          />
        </Col>
      </Row>
    </FormModal>
  )
}

GroupsFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

GroupsFormModal.defaultProps = {
  title: 'Gruppi'
}

export default reduxForm({
  form: 'groupsFormModal'
})(GroupsFormModal)
