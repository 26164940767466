import React, { useState } from 'react'
import cx from 'classnames'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Modal
} from 'reactstrap'
import Loader from 'components/Loader'

import { fromModalPropTypes } from './formModalPropTypes'

import styles from './FormModal.module.scss'

const FormModal = (props) => {
  const {
    formTitle,
    children,
    isOpen,
    toggle,
    handleSubmit,
    onClose,
    reset,
    className
  } = props

  const resetForm = () => {
    toggle()
    reset()
    setOnSubmitting(false)
  }

  const onClosed = () => {
    onClose && onClose()
    setOnSubmitting(false)
    reset()
  }

  const [onSubmitting, setOnSubmitting] = useState(false)

  return (
    <Modal className={cx(styles.FormModal, className)} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <Card>
        <Form onSubmit={(e) => {
          e.preventDefault()
          setOnSubmitting(true)
          handleSubmit(e)
        }}>
          <CardHeader className='d-flex align-items-center'>
            <i className='fa fa-align-justify' />
            <span>{formTitle}</span>
          </CardHeader>
          <CardBody>
            {children}
          </CardBody>
          <CardFooter>
            <ButtonGroup>
              {(onSubmitting === false || props.submitFailed === true) ? (
                <>
                  <Button className='mr-3' color='success' size='xs'>
                    <i className='fa fa-check' style={{ color: '#ffffff' }} />
                  </Button>
                  <Button color='danger' size='xs' onClick={resetForm}>
                    <i className='fa fa-times' style={{ color: '#ffffff' }} />
                  </Button>
                </>
              ) : (
                <Loader />
              )}
            </ButtonGroup>
          </CardFooter>
        </Form>
      </Card>
    </Modal>
  )
}

FormModal.propTypes = {
  ...fromModalPropTypes
}

FormModal.defaultProps = {
  submitButtonLabel: 'Submit',
  cancelButtonLabel: 'Cancel',
  formTitle: 'Form'
}

export default FormModal
