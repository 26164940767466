import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Input, Label, Util } from 'reactstrap'

import FileUploadService from 'services/FileUploadService'
import LoadingDots from 'components/LoadingDots'

import store from 'store/store'

import styles from './UploadFileInput.module.scss'

const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func
  })
}

class UploadFileInput extends Component {
  state = {
    isLoaded: false,
    isLoading: false,
    selectedFile: null,
    uploadedFileData: null
  }

  onChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const state = { selectedFile: file, isLoaded: false, isLoading: true, uploadedFileData: null }
      this.setState(state, () => {
        FileUploadService
          .upload(file)
          .then((response) => {
            const { data: { uploadedFileData } } = response

            this.setState({ selectedFile: file, isLoaded: true, isLoading: false, uploadedFileData: uploadedFileData }, () => {
              const { onChange } = this.props.input
              const { uploadedFileData: { url } } = this.state
              onChange(url)
            })
          })
          .catch((error) => {
            this.setState({ isLoaded: false, isLoading: false, uploadedFileData: null }, () => {
              const responseError = error.response ? error.response.data : { message: 'Error', details: 'Unknown Error' }

              store.dispatch({
                type: '@@redux-form/STOP_SUBMIT',
                error: true,
                meta: { form: 'addCoverModalForm' },
                payload: {
                  _error: responseError
                }
              })
            })
          })
      })
    }
  }

  onBlur = () => {
    // don't call obBlur cb
  }

  render () {
    const {
      input,
      className,
      id,
      placeholder,
      invalid,
      disabled
    } = this.props

    const { selectedFile, isLoading, isLoaded } = this.state

    const { value, ...inputProps } = input
    const { name } = input

    let fileLoadingStatusText = 'File is not selected'
    if (selectedFile && isLoaded) {
      fileLoadingStatusText = `Selected file: ${selectedFile.name}`
    }
    if (isLoading) {
      fileLoadingStatusText = 'Wait until file loaded'
    }

    const labelClasses = Util.mapToCssModules(
      cx('label-button', 'btn btn-secondary text-center d-inline-block mr-auto d-flex align-items-baseline', className),
      styles
    )

    const inputClasses = Util.mapToCssModules(
      cx('file-input', className),
      styles
    )

    return (
      <Fragment>
        <div className='d-flex flex-column'>
          <Label className={labelClasses} htmlFor={name}>
            File
            {isLoading && <LoadingDots color='#000000' />}
          </Label>
          <span className={cx({ 'text-danger': invalid })}>{fileLoadingStatusText}</span>
        </div>
        <Input {...inputProps}
          id={id}
          className={inputClasses}
          name={name}
          type='file'
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </Fragment>
    )
  }
}

UploadFileInput.propTypes = propTypes

export default UploadFileInput
