import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { Field, FieldArray, formPropTypes, formValueSelector, reduxForm, change } from 'redux-form'

import {
  renderFormGroupTextField,
  renderSelectMappingField,
  renderFileUploadInput
} from 'components/forms/formFields'
import { required } from 'components/forms/validators'
import ResponseError from 'components/ResponseError'

import FormModal from '../FormModal'

import documentFormModal from './DocumentFormModal.module.scss'
import styles from '../FormModalBase.module.scss'
import { renderPermissions } from './renderPermissions'

const DocumentFormModal = (props) => {
  const { title, error, user, disabled, access, categories, users, groups, usersGroupsIsLoading, modalState } = props
  const author = props.initialValues ? props.initialValues.author : null

  const [usersMapping, setUsersMapping] = useState([])
  const [groupsMapping, setGroupsMapping] = useState([])

  useEffect(() => {
    setUsersMapping(users
      .filter(({ _id }) => _id !== user._id)
      .reduce((memo, { _id, name, surname, email }) => {
        memo[_id] = `${name} ${surname} (${email})`
        return memo
      }, {}))
  }, [users])

  useEffect(() => {
    setGroupsMapping(
      groups
        .filter(group => {
          return author === user._id || !group.users.find(({ _id }) => _id === user._id)
        })
        .reduce((memo, { _id, name }) => {
          memo[_id] = name
          return memo
        }, {}))
  }, [groups])

  const categoriesMapping = categories.reduce((memo, category) => {
    memo[category['_id']] = category['title']
    return memo
  }, {})

  const onAddPermission = (fields) => {
    fields.push({
      type: '',
      grantee: '',
      access: 'view',
      isNew: true
    })
  }

  const onRemovePermission = (fields, index) => {
    fields.remove(index)
  }

  const onPermissionTypeChange = (field) => {
    props.change('documentFormModal', `${field}.grantee`, '')
  }

  return (
    <FormModal className={cx(styles.FormModalBase, documentFormModal.DocumentFormModal)} {...props} formTitle={title}>
      {error && <ResponseError error={error} />}
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Titolo'
            name='title'
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Descrizione'
            name='description'
            type='textarea'
            validate={[]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Categoria'
            name='category'
            mapping={categoriesMapping}
            validate={[required]}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFileUploadInput}
            label='File'
            name='file'
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderFormGroupTextField}
            label='Versione'
            name='version'
            validate={[required]}
          />
        </Col>
      </Row>
      {(!access || access === 'edit') && (
        <Row>
          <Col>
            <FieldArray name='permissions'
              useCustoms
              disabled={disabled || !modalState.useCustomPermissions}
              useCustomPermissions={modalState.useCustomPermissions}
              permissions={modalState.permissions || []}
              users={usersMapping}
              groups={groupsMapping}
              isLoading={usersGroupsIsLoading}
              onPermissionTypeChange={onPermissionTypeChange}
              onAddPermission={onAddPermission}
              onRemovePermission={onRemovePermission}
              component={renderPermissions} />
          </Col>
        </Row>
      )}
    </FormModal>
  )
}

DocumentFormModal.propTypes = {
  ...formPropTypes,
  title: PropTypes.string
}

DocumentFormModal.defaultProps = {
  title: 'Documento',
  categories: [],
  users: [],
  groups: []
}

const selector = formValueSelector('documentFormModal')

const mapStateToProps = (state) => {
  const permissions = selector(state, 'permissions')
  const useCustomPermissions = selector(state, 'useCustomPermissions')

  return {
    user: state.auth.user,
    modalState: {
      useCustomPermissions: useCustomPermissions,
      permissions: permissions
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change: (form, field, value) => { dispatch(change(form, field, value)) }
  }
}

export default reduxForm({
  form: 'documentFormModal',
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(DocumentFormModal))
