import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { navigationMenuBuilder } from 'helpers/menuBuilder'

const NavigationMenu = (props) => {
  const { className, isStaff, isEditor, isAgent } = props

  const navigationMenuItems = navigationMenuBuilder({
    isStaff: isStaff,
    isEditor: isEditor,
    isAgent: isAgent
  }).map(({ label, link }) => {
    return (
      <li key={label} className={cx('nav-item', className)}>
        <NavLink className='nav-link' activeClassName='active' to={link}>
          {label}
        </NavLink>
      </li>
    )
  })

  return (
    <ul className='nav navbar-nav'>
      { navigationMenuItems}
      {isAgent && (
        <li className={cx('nav-item', className)}>
          <a className='nav-link' href='http://www.epdeagenti.it/Home.aspx' target='_blank'>App Agenti</a>
        </li>
      )}
      <li className={cx('nav-item', className)}>
        <a className='nav-link' href='ftp://172.16.4.3' target='_blank'>FTP accesso PDE</a>
      </li>
      <li className={cx('nav-item', className)}>
        <a className='nav-link' href='ftp://ftp.feltrinelli.it' target='_blank'>FTP accesso Pubblico</a>
      </li>
    </ul >
  )
}

NavigationMenu.propTypes = {
  className: PropTypes.string,
  isStaff: PropTypes.bool
}

NavigationMenu.defaultProps = {
  isStaff: false
}

export default NavigationMenu
