const NAVIGATION_MENU_CFG = {
  USERS: {
    label: 'Utenti',
    link: '/users'
  },
  GROUPS: {
    label: 'Gruppi',
    link: '/groups'
  },
  DOCUMENTS: {
    label: 'Documenti',
    link: '/documents'
  }
}

export const navigationMenuBuilder = ({ isStaff, isEditor, isAgent }) => {
  if (isStaff) {
    return [
      NAVIGATION_MENU_CFG.USERS,
      NAVIGATION_MENU_CFG.GROUPS,
      NAVIGATION_MENU_CFG.DOCUMENTS
    ]
  }

  if (isEditor || isAgent) {
    return [
      NAVIGATION_MENU_CFG.DOCUMENTS
    ]
  }

  return []
}
