import User from './user'

class Group {
  constructor (data) {
    this._id = data._id
    this.name = data.name
    this.users = (data.users || []).map(data => new User(typeof data === 'string' ? { _id: data } : data))
    this.editors = (data.editors || []).map(data => new User(typeof data === 'string' ? { _id: data } : data))
  }
}

export default Group
