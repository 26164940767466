import React, { useState } from 'react'

import { ActionsFormatterGeneric } from 'components/tables/formatters'
import Group from 'models/group'

import GroupFormModal from 'components/modals/GroupFormModal'
import withAuth from 'containers/auth.container'

import DataTable from '../DataTable'
import GroupsService from 'services/GroupsService'
import UsersListFormatter from './formatters/UsersListFormatter'

const getColumns = (props) => (
  [
    {
      key: 'name',
      name: 'Nome',
      width: '200px'
    },
    {
      key: 'users',
      name: 'Utenti',
      formatter: UsersListFormatter
    },
    {
      key: 'actions',
      name: 'Azioni',
      formatter: ActionsFormatterGeneric(props),
      cellClass: 'justify-content-center'
    }
  ]
)

const UsersDataTable = () => {
  const [groups, setGroups] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const fetchUsers = ({ page: { number, size }, formFilterData }) => {
    GroupsService
      .paginate(number, size, formFilterData)
      .then((response) => {
        const { data: { results, totalRecords } } = response
        const groups = results.map(response => new Group(response))

        setGroups(groups)
        setTotalRows(totalRecords)
      })
      .catch(() => { })
  }

  const onChange = (params) => {
    fetchUsers(params)
  }

  const handleSubmit = (action) => {
    return (formValues) => {
      const body = { ...formValues }

      let request = GroupsService[action]

      if (request) {
        if (action === 'create') {
          request = request(body)
        }

        if (action === 'update') {
          const { _id } = body
          request = request(_id, body)
        }

        if (action === 'delete') {
          const { _id } = body
          request = request(_id, body)
        }

        return request
      }
    }
  }

  const getFormModalModeProps = (mode) => {
    if (mode === 'create') {
      return {
        title: 'Crea gruppo',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'update') {
      return {
        title: 'Aggiorna gruppo',
        onSubmit: handleSubmit(mode)
      }
    }

    if (mode === 'delete') {
      return {
        title: 'Elimina gruppo',
        onSubmit: handleSubmit(mode)
      }
    }

    return {
      submitButtonLabel: 'Invia',
      title: 'Gruppo'
    }
  }

  const prepareModalData = (data) => {
    return {
      ...data,
      users: data.users ? data.users.map(({ _id }) => _id) : []
    }
  }

  return (
    <DataTable columns={getColumns}
      prepareModalData={prepareModalData}
      data={groups}
      totalRowsCount={totalRows}
      onChange={onChange}
      formModalProps={{
        formModalComponent: GroupFormModal,
        getFormModalModeProps: getFormModalModeProps
      }}
    />
  )
}

export default withAuth(UsersDataTable)
