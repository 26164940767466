import React from 'react'

export default ({ value }) => {
  const users = value

  return (
    <div className='text-left w-100' style={{ maxHeight: '100px', overflowY: 'scroll' }}>
      {users.map(({ _id, name, surname }) => (
        <div key={_id}>{name} {surname}</div>
      ))}
    </div>
  )
}
