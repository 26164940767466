import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import authReducer from './auth'

export default (asyncReducers) =>
  combineReducers({
    auth: authReducer,
    form: formReducer,
    ...asyncReducers
  })
