import React, { Fragment, useState } from 'react'
import { CardText } from 'reactstrap'

import handleFormSubmitError from 'helpers/forms/handleFormSubmitError'
import AuthService from 'services/AuthService'
import ResetPasswordForm from 'components/forms/ResetPasswordForm'
import SimpleFormView from './SimpleFormView'

const ResetPasswordView = () => {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const onSubmit = ({ email }) => {
    return AuthService
      .recover(email)
      .then(() => {
        setSent(true)
        setEmail(email)
      })
      .catch((error) => {
        handleFormSubmitError(error)
      })
  }

  let cardBody = null
  if (sent) {
    cardBody = (
      <Fragment>
        <CardText>Abbiamo inviato un link per reimpostare la password al tuo indirizzo email.</CardText>
        <CardText><span className='h3'>{email}</span></CardText>
      </Fragment>
    )
  } else {
    cardBody = (
      <Fragment>
        <h1>Password dimenticata?</h1>
        <p className='text-muted'>Inserisci il tuo indirizzo email e ti invieremo un link per reimpostare la tua password.</p>
        <ResetPasswordForm onSubmit={onSubmit} />
      </Fragment>
    )
  }

  return (
    <SimpleFormView>
      {cardBody}
    </SimpleFormView>
  )
}

export default ResetPasswordView
