import React from 'react'
import { Field } from 'redux-form'
import { Label } from 'reactstrap'

import DataTableFilterForm from 'components/forms/DataTableFilterForm'
import { renderFormGroupTextField } from '../forms/formFields'

const createDataTableFilter = (props) => {
  const {
    className,
    columns,
    onSubmitFilter,
    onResetFilter
  } = props

  const filterColumns = Array.isArray(columns) ? columns : columns()

  const dataTableFilterFields = filterColumns.reduce((memo, { key, name, filterable, filterRenderComponent: FilterRenderComponent }) => {
    if (filterable) {
      const filterComponent = FilterRenderComponent ? (
        <FilterRenderComponent key={key} filterKey={key} name={name} />
      ) : (
        <div key={key} className='d-flex flex-row align-items-center mr-2 ml-2' style={{ flex: 1 }}>
          <Label className='m-0 mr-2' htmlFor={key}>{name}</Label>
          <Field
            component={renderFormGroupTextField}
            name={key}
          />
        </div>
      )

      memo.push(filterComponent)
    }

    return memo
  }, [])

  if (dataTableFilterFields.length === 0) {
    return null
  }

  return (
    <DataTableFilterForm className={className} onSubmit={onSubmitFilter} onReset={onResetFilter}>
      <div className='d-flex justify-content-around'>
        {dataTableFilterFields}
      </div>
    </DataTableFilterForm>
  )
}

export default createDataTableFilter
