import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class CategoryService {
  async create (body) {
    return axios.post(`${API_ENDPOINT}/categories`, { data: body })
  }

  async index () {
    return axios.get(`${API_ENDPOINT}/categories`)
  }

  async delete (id) {
    return axios.delete(`${API_ENDPOINT}/categories/${id}`)
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/categories/${id}`, { data: body })
  }

  async documents (id) {
    return axios.get(`${API_ENDPOINT}/categories/${id}/documents`)
  }

  async updateFolderPosition (body) {
    return axios.patch(`${API_ENDPOINT}/categories/updateFolderPosition`, { data: body })
  }
}

export default new CategoryService()
