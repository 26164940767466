import React from 'react'
import cx from 'classnames'
import { Button, Input, Label, FormGroup } from 'reactstrap'

import styles from './DocumentsTreeToolbar.module.scss'

export default (props) => {
  const {
    className,
    searchString,
    searchFoundCount,
    onExpandAll,
    onCollapseAll,
    onSelectPrevMatch,
    onSelectNextMatch,
    onSearchStringChange
  } = props

  return (
    <div className={cx('d-flex align-items-center', styles.DocumentsTreeToolbar, className)}>
      <div>
        <Button className='mr-2' color='primary' size='xs' onClick={onExpandAll}>
          <i className='fas fa-angle-double-down' style={{ color: '#ffffff' }} />
        </Button>
        <Button color='primary' size='xs' onClick={onCollapseAll}>
          <i className='fas fa-angle-double-up' style={{ color: '#ffffff' }} />
        </Button>
      </div>

      <form className='d-flex ml-auto' onSubmit={event => { event.preventDefault() }}>
        <FormGroup className='d-flex align-items-center m-0'>
          <Label htmlFor='find-box'>
            Cerca:&nbsp;
          </Label>

          <Input
            id='find-box'
            type='text'
            className={styles.SearchInput}
            value={searchString}
            onChange={event => {
              onSearchStringChange && onSearchStringChange(event.target.value)
            }}
          />
        </FormGroup>
        <FormGroup className='d-flex align-items-center m-0'>
          <Button
            type='button'
            color='success'
            className='mr-1'
            disabled={!searchFoundCount}
            onClick={onSelectPrevMatch}
          >
            &lt;
          </Button>

          <Button
            type='submit'
            color='success'
            disabled={!searchFoundCount}
            onClick={onSelectNextMatch}
          >
            &gt;
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}
