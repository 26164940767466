import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class FileUploadService {
  async upload (file) {
    if (!(file instanceof File)) {
      throw new Error('Incorrect file format')
    }

    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return axios.post(`${API_ENDPOINT}/file/upload`, formData, config)
  }

  async download (id) {
    return axios.post(`${API_ENDPOINT}/file/download/${id}`, {}, {
      responseType: 'blob'
    })
  }
}

export default new FileUploadService()
