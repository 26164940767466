import React from 'react'
import NavigationMenu from './NavigationMenu'

const Sidebar = ({ isStaff, isEditor, isAgent }) => {
  return (
    <div className='sidebar'>
      <nav className='sidebar-nav'>
        <NavigationMenu isStaff={isStaff}
          isEditor={isEditor}
          isAgent={isAgent} />
      </nav>
    </div>
  )
}

export default Sidebar
