import React, { Component } from 'react'
import SortableTree from 'react-sortable-tree'
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer'

import IconDirectory from './IconDirectory'
import IconFile from './IconFile'

export default class DocumentsTreeView extends Component {
  render () {
    const {
      searchQuery,
      searchFocusOffset,
      generateNodeProps,
      searchMethod,
      treeData,
      canDrop,
      onChange,
      onMoveNode,
      onSearchFinishCallback
    } = this.props

    return (
      <SortableTree
        className='h-100 w-100'
        theme={FileExplorerTheme}
        treeData={treeData}
        onChange={onChange}
        rowHeight={32}
        searchQuery={searchQuery}
        searchMethod={searchMethod}
        searchFocusOffset={searchFocusOffset}
        searchFinishCallback={onSearchFinishCallback}
        canDrag={({ node }) => !node.dragDisabled}
        canDrop={canDrop}
        onMoveNode={onMoveNode}
        generateNodeProps={rowInfo => {
          const { node } = rowInfo

          const nodeProps = {
            icons: rowInfo.node.isDirectory ? [<IconDirectory rowInfo={rowInfo} />] : [<IconFile rowInfo={rowInfo} />],
            className: node.isDirectory ? 'rstcustom__DirectoryNode' : 'rstcustom__DirectoryFile'
          }

          return generateNodeProps ? generateNodeProps(nodeProps, rowInfo) : nodeProps
        }}
      />
    )
  }
}
