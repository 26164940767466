class User {
  constructor (userData) {
    this._id = userData._id
    this.email = userData.email
    this.name = userData.name
    this.surname = userData.surname
    this.language = userData.language
    this.role = userData.role
    this.permissions = userData.permissions
    this.active = userData.active
  }

  static fromResponse = (userData) => {
    return new User(userData)
  }
}

export default User
