import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class DocumentService {
  async index () {
    return axios.get(`${API_ENDPOINT}/documents`)
  }

  async create (body) {
    return axios.post(`${API_ENDPOINT}/documents`, { data: body })
  }

  async update (id, body) {
    return axios.patch(`${API_ENDPOINT}/documents/${id}`, { data: body })
  }

  async delete (id) {
    return axios.delete(`${API_ENDPOINT}/documents/${id}`)
  }

  async updateFolderPosition (body) {
    return axios.patch(`${API_ENDPOINT}/documents/updateFolderPosition`, { data: body })
  }
}

export default new DocumentService()
