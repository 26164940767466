import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import NotificationSettingsForm from 'components/forms/NotificationSettingsForm'
import UsersService from 'services/UsersService'
import SettingsService from 'services/SettingsService'
import Loader from 'components/Loader'

import styles from './Notifications.module.scss'
import GroupsService from '../../../../services/GroupsService'
import { AGENT } from 'constants/users'

const Notifications = () => {
  const [isLoading, setLoading] = useState(true)
  const [users, setUsers] = useState({ isLoading: false, mapping: [] })
  const [groups, setGroups] = useState({ isLoading: false, mapping: [] })

  const [settings, setSettings] = useState({})

  useEffect(() => {
    setUsers({ isLoading: true, mapping: [] })
    setGroups({ isLoading: true, mapping: [] })

    Promise.all([
      UsersService.index(),
      GroupsService.index()
    ]).then(values => {
      const users = values[0].data.users
        .filter(({ role }) => {
          return role === AGENT
        }).map(({ _id, name, surname, email }) => {
          return { value: _id, label: `${name}  ${surname} (${email})` }
        })
      const groups = values[1].data.groups.map(({ _id, name }) => {
        return { value: _id, label: name }
      })

      setUsers({ isLoading: false, mapping: users })
      setGroups({ isLoading: false, mapping: groups })
    }).catch(_ => {
      setUsers({ isLoading: false, mapping: [] })
    })

    SettingsService
      .index()
      .then(({ data }) => {
        const { settings } = data
        setLoading(false)
        setSettings(settings)
      })
  }, [])

  const saveChanges = (values) => {
    SettingsService
      .update(values)
      .then(({ data }) => {
        const { settings } = data
        toast.success('Impostazioni aggiornate')
        setSettings(settings)
      })
  }

  return (
    <div className={styles.Notifications}>
      <section className={styles.Container}>
        <h2>Impostazioni di notifica</h2>
        <section>
          {isLoading ? (
            <Loader />
          ) : (
            <NotificationSettingsForm onSubmit={saveChanges} users={users} groups={groups} initialValues={settings} />
          )}
        </section>
      </section>
    </div>
  )
}

export default Notifications
