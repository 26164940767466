import React from 'react'
import cx from 'classnames'
import Select from 'react-select'

import styles from './selectStyles'

export default (props) => {
  const { disabled, invalid } = props

  return (
    <Select
      {...props}
      className={cx('react-select-container', { 'react-select-invalid': invalid })}
      classNamePrefix='react-select'
      styles={styles}
      isDisabled={disabled}
      components={{
        DropdownIndicator: null
      }}
    />
  )
}
