import axios from 'libs/axios'
import { API_ENDPOINT } from 'config'

class SettingsService {
  async index () {
    return axios.get(`${API_ENDPOINT}/notifications`)
  }

  async update (body) {
    return axios.patch(`${API_ENDPOINT}/notifications`, { data: body })
  }
}

export default new SettingsService()
