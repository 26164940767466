import React, { Fragment } from 'react'
import { Button, Col, Label, Row } from 'reactstrap'
import { Field } from 'redux-form'

import { renderSelectMappingField } from 'components/forms/formFields'
import { required } from 'components/forms/validators'
import Accordion from 'components/Accordion'

import styles from './DocumentFormModal.module.scss'

const GRANTEE_TYPES_MAPPING = {
  group: 'Group',
  user: 'User'
}
const ACCESS_MAPPING = {
  view: 'View',
  edit: 'Edit'
}

export const renderPermissions = (props) => {
  const {
    fields,
    disabled,
    users,
    groups,
    isLoading,
    permissions,
    onPermissionTypeChange,
    onAddPermission,
    onRemovePermission,
    useCustoms,
    useCustomPermissions
  } = props

  const selectedUsers = permissions.reduce((memo, { permissionType, grantee }) => {
    if (permissionType === 'user' && grantee && grantee !== '') {
      memo.push(grantee)
    }

    return memo
  }, [])

  const selectedGroups = permissions.reduce((memo, { permissionType, grantee }) => {
    if (permissionType === 'group' && grantee && grantee !== '') {
      memo.push(grantee)
    }

    return memo
  }, [])

  const addPermission = () => {
    if (onAddPermission) {
      onAddPermission(fields)
    }
  }

  const removePermission = (index) => {
    if (onRemovePermission) {
      onRemovePermission(fields, index)
    }
  }

  return (
    <Fragment>
      <h4 className='d-flex justify-content-between align-items-center'>
        Imposta permessi
        {useCustoms && <Label className='p-0 m-0 d-flex align-items-center' for='useCustomPermissions'>
          <Field name='useCustomPermissions' id='useCustomPermissions' component='input' type='checkbox' />
          <span className='ml-2' style={{ fontSize: '14px' }}>Applica regole personalizzate</span>
        </Label>}
      </h4>
      <div className='d-flex mb-2'>
        <Button className='ml-auto' color='primary' size='xs' onClick={addPermission} disabled={useCustoms && !useCustomPermissions}>
          <i className='fa fa-plus mr-2' />
          Aggiungi
        </Button>
      </div>
      {fields.map((perm, index) => {
        const { permissionType, grantee, isNew } = permissions[index]
        let granteeMapping = []

        if (permissionType === 'user') {
          granteeMapping = Object.keys(users).reduce((memo, key) => {
            if (key === grantee || !selectedUsers.includes(key)) {
              memo[key] = users[key]
            }

            return memo
          }, {})
        }

        if (permissionType === 'group') {
          granteeMapping = Object.keys(groups).reduce((memo, key) => {
            if (key === grantee || !selectedGroups.includes(key)) {
              memo[key] = groups[key]
            }

            return memo
          }, {})
        }

        return (
          <Accordion key={index} className={styles.Accordion} editable={!disabled} expandOnMound={isNew} header='Permesso'>
            <Row>
              <Col style={{ flex: 3 }}>
                <Field
                  component={renderSelectMappingField}
                  label='Tipo'
                  name={`${perm}.permissionType`}
                  mapping={GRANTEE_TYPES_MAPPING}
                  onChange={() => onPermissionTypeChange(perm)}
                  validate={[required]}
                />
              </Col>
              <Col className='position-relative'>
                <Button className='position-absolute' color='danger' size='xs' onClick={() => removePermission(index)} style={{ right: '16px' }}>
                  <i className='fas fa-trash' style={{ color: '#ffffff' }} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={renderSelectMappingField}
                  isLoading={isLoading}
                  label='Utente/Gruppo'
                  mapping={granteeMapping}
                  name={`${perm}.grantee`}
                  validate={[required]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={renderSelectMappingField}
                  label='Livello di accesso'
                  name={`${perm}.access`}
                  mapping={ACCESS_MAPPING}
                  validate={[required]}
                />
              </Col>
            </Row>
          </Accordion>
        )
      })}
    </Fragment>
  )
}
