import React, { Component } from 'react'
import {
  Alert,
  Col,
  Row
} from 'reactstrap'
import { NavLink } from 'react-router-dom'

import SignInForm from 'components/forms/SignInForm'
import AuthService from 'services/AuthService'
import SimpleFormView from 'containers/views/SimpleFormView'

import withAuth from 'containers/auth.container'

class SignInView extends Component {
  onSubmit = async (values) => {
    const { email, password } = values

    AuthService.authLoginUserRequest(email, password)
  }

  renderError = (error) => {
    if (!error) {
      return null
    }

    const { details } = error
    return (
      <Row>
        <Col>
          <Alert color='danger'>{details}</Alert>
        </Col>
      </Row>
    )
  }

  render () {
    const {
      isRestoring,
      error
    } = this.props

    return (
      <SimpleFormView isLoading={isRestoring} loadingText='Authorizing...'>
        <h1>Login</h1>
        <p className='text-muted'>Accedi al tuo account</p>
        {this.renderError(error)}
        <SignInForm onSubmit={this.onSubmit} />
        <NavLink className='d-flex align-items-center text-decoration-none mt-4' to='/recover' style={{ lineHeight: '16px' }}>
          Hai dimenticato la password?
          <i className='fa fa-arrow-right fa-xs ml-1' />
        </NavLink>
      </SimpleFormView>
    )
  }
}

export default withAuth(SignInView)
