import React, { useMemo } from 'react'

export default (props) => {
  const { rowInfo } = props

  const hasNewDocuments = useMemo(() => {
    const now = Date.now()

    return (rowInfo.node.children || []).some(document => {
      const creationDate = new Date(document.createdAt)
      const diff = Math.ceil((now - creationDate) / (1000 * 60 * 60 * 24))
      return diff <= 2
    })
  }, [rowInfo])

  const inlineStyles = hasNewDocuments
    ? { color: '#17a2b8' }
    : {}

  return (
    <div className='mr-2'>
      {rowInfo.node.expanded
        ? <i className='fas fa-folder-open' style={inlineStyles} />
        : <i className='fas fa-folder' style={inlineStyles} />}
    </div>
  )
}
