import React from 'react'
import {
  Field,
  formPropTypes,
  reduxForm
} from 'redux-form'

import { renderFormGroupTextField } from './formFields'
import { required, minLength } from './validators'

const minLengthValidator = minLength(8)

const ChangePasswordForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label='Password Attuale'
        type='password'
        name='currentPassword'
        icon='lock'
        placeholder='Inserisci la tua password corrente'
        component={renderFormGroupTextField}
        validate={[required]}
      />

      <Field
        label='Nuova Password'
        type='password'
        name='newPassword'
        icon='lock'
        placeholder='Inserisci la nuova password'
        component={renderFormGroupTextField}
        validate={[required, minLengthValidator]}
      />

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Salva
      </button>
    </form>
  )
}

ChangePasswordForm.propTypes = formPropTypes

export default reduxForm({
  form: 'changePasswordForm'
})(ChangePasswordForm)
