import React from 'react'
import {
  Field,
  Form,
  formPropTypes,
  reduxForm
} from 'redux-form'
import { Col, Row } from 'reactstrap'

import { renderSelectMappingField } from './formFields'

const NotificationSettingsForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    users,
    groups
  } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            name='groups'
            label='Gruppi'
            isMulti
            isLoading={groups.isLoading}
            mapping={groups.mapping}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={renderSelectMappingField}
            label='Utenti'
            name='users'
            isMulti
            isLoading={users.isLoading}
            mapping={users.mapping}
          />
        </Col>
      </Row>

      <button className='btn btn-primary btn-block'
        type='submit'
        disabled={submitting || pristine || invalid}>
        Salva
      </button>
    </Form>
  )
}

NotificationSettingsForm.propTypes = formPropTypes

export default reduxForm({
  form: 'notificationSettingsForm',
  enableReinitialize: true
})(NotificationSettingsForm)
