import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import withAuth from 'containers/auth.container'
import { ADMIN, EDITOR, AGENT } from 'constants/users'

const REDIRECT_PATH = {
  [ADMIN]: '/users',
  [EDITOR]: '/documents',
  [AGENT]: '/documents'
}

const OnlyPublicRoute = ({ isAuthenticated, component: Component, layout: Layout, ...rest }) => {
  const { user } = rest

  return (
    <Route {...rest} render={props => (
      !isAuthenticated ? (
        Layout === undefined ? (
          <Component {...props} />
        ) : (
          <Layout><Component {...props} /></Layout>
        )
      ) : (
        <Redirect to={{
          pathname: REDIRECT_PATH[user.role],
          state: { from: props.location }
        }} />
      )
    )} />
  )
}

export default withAuth(OnlyPublicRoute)
